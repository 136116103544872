<template>
  <div class="login flex w-full vx-row no-gutter items-center justify-center" id="page-login">
    <div class="card">
      <div class="logo">
        <img :src="clubInfo.club_logo || './logo.png'" alt="logo" class="logo-img">
      </div>
      <h4 v-if="step === 'GET_PHONE_NUMBER'" class="login-title">
        {{ $t('login.clubTitle', {name: clubInfo ? clubInfo.club_name : ''}) }}
      </h4>
      <h4 v-if="step === 'OTP_CODE_SENT'" class="login-title">
        {{ $t('login.confirmPhoneNumber') }}
      </h4>
      <p v-if="step === 'GET_PHONE_NUMBER'" class="login-content" v-html="$t('login.getPhoneNumberMessage')">
      </p>
      <p v-else-if="step === 'OTP_CODE_SENT'" class="login-content">
        {{ $t('login.otpSend') }}
      </p>
      <h4 v-if="step === 'OTP_CODE_SENT'" class="login-title mt-2" :class="$vs.rtl ? 'rtl' : 'ltr'">
        {{ phoneNumber.value }}
      </h4>
      <div v-if="step === 'GET_PHONE_NUMBER'" class="get-phone-number">
        <transition name="fade">
          <u-phone-input v-if="step === 'GET_PHONE_NUMBER'"
                         v-model="phoneNumber"
                         :label="$t('login.phoneNumber')"
                         auto-focus
                         is-empty
                         :default-country-code="clubInfo.club_default_country_code"
                         :invalid-text="phoneNumber.error"
                         @pressEnter="changeStep('OTP_CODE_SENT')"
          />
        </transition>
        <div id="validationPhoneNumberFeedback" class="invalid-feedback">
          {{ phoneNumber.invalid }}
        </div>
      </div>
      <template v-if="step === 'OTP_CODE_SENT'">
        <custom-otp-input
            v-if="!resendOtp.status"
            ref="otpInput"
            input-classes="otp-input"
            separator=" "
            :num-inputs="OPTCode.length"
            inputType="number"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-change="handleOnChangeCode"
            @on-complete="handleOnCompleteCode"/>
        <div class="resend">
          <p v-if="!resendOtp.status">
            {{ $t('login.labels.codeValidate') }}
            <timer init-timer="60" :reset="resendOtp.reset" v-model="resendOtp.status"/>
            {{ $t('login.labels.second') }}
          </p>
          <button v-if="resendOtp.status" class="resend-btn" @click="resendOtpCode">
            {{ $t('login.labels.resendCode') }}
          </button>
        </div>
      </template>

      <div class="btns">
        <button v-if="step === 'GET_PHONE_NUMBER'" class="btn send-btn"
                :disabled="!phoneNumber.isValid || phoneNumber.value.length === 0"
                @click="changeStep('OTP_CODE_SENT')">
          {{ $t('login.labels.sendOtp') }}
        </button>
        <!--        <button v-else-if="step === 'OTP_CODE_SENT'" class="btn send-btn" :disabled="OPTCode.value.length !== OPTCode.length" @click="changeStep('signup')">-->
        <!--          ورود-->
        <!--        </button>-->
      </div>
    </div>
    <img v-if="step === 'OTP_CODE_SENT'" class="back-icon"
         src="https://img.icons8.com/material-outlined/96/ffffff/right.png"
         @click="step = 'GET_PHONE_NUMBER', phoneNumber.value = ''"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {resendOTPCode, sendOTPCode} from '@/http/requests/auth'
import CustomOtpInput from '../../components/customOTPInput/customOtpInput.vue'
import Timer from '../../components/Timer/Timer'
// import RulesModal from '../../components/modals/RulesModal'
import CustomIcon from '../../components/customIcon/customIcon'
import data from '../../assets/js/data'
import {convertPersianNumberToEnglish, getAvatarUrl} from '@/assets/js/functions'
import CustomPhoneNumberInput from "../../components/customInput/customPhoneNumberInput";
import CustomValidateInput from "../../components/customInput/customValidateInput";
import UPhoneInput from "../../components/customInput/UPhoneInput.vue";
// import CustomSelect from '../components/customSelect/customSelect'

export default {
  name: 'Login',

  head: {
    title: 'ورود'
  },

  components: {
    UPhoneInput,
    CustomValidateInput,
    CustomPhoneNumberInput,
    CustomIcon,
    // RulesModal,
    Timer,
    // CustomSelect,
    CustomOtpInput
  },
  created() {
  },

  data() {
    return {
      club: {
        name: '',
        logo: ''
      },
      step: 'GET_PHONE_NUMBER',
      // step: 'OTP_CODE_SENT',
      // step: 'signup',
      phoneNumber: {
        value: '',
        errors: '',
        isValid: true
      },
      OPTCode: {
        value: '',
        isValid: true,
        length: 5
      },

      resendOtp: {
        reset: false,
        status: false
      },

      user: {
        firstName: {
          value: '',
          invalid: ''
        },
        lastName: {
          value: '',
          invalid: ''
        },
        gender: {
          value: {
            id: -1,
          },
          error: false
        },
        acceptRolls: false
      },
    }
  },

  computed: {
    ...mapGetters({
      clubInfo: 'setting/getClubInfo'
    }),
    isSignupValid() {
      if (this.user.gender.value.id > 0 && !this.user.gender.error &&
          this.user.firstName.value.length > 0 && this.user.firstName.invalid.length === 0 &&
          this.user.lastName.value.length > 0 && this.user.lastName.invalid.length === 0 && this.user.acceptRolls
      ) {
        return true
      } else {
        return false
      }
    },

    data() {
      return data
    }
  },

  watch: {
    // 'phoneNumber.value': {
    //     handler(val, prev) {
    //         if (val.length > 11) {
    //             this.phoneNumber.value = prev
    //         }
    //         // this.phoneNumberValidator()
    //     }
    // },
  },

  methods: {
    phoneNumberValidator() {
      // convert persian digit to english digit
      this.phoneNumber.value = convertPersianNumberToEnglish(this.phoneNumber.value)

      this.phoneNumber.value = this.phoneNumber.value.toString().replace(/\D/g, '')

      this.phoneNumber.isValid = this.$validator('regex.user.phoneNumber').test(this.phoneNumber.value)
      if (!this.phoneNumber.isValid) {
        if (!this.phoneNumber.value.startsWith(this.$validator('regex.user.phoneNumberStartWith'))) {
          this.phoneNumber.error = this.$t('login.validators.phoneStart')
        } else if (this.phoneNumber.value.length < 11) {
          this.phoneNumber.error = this.$t('login.validators.phoneNumberLength')
        } else {
          this.phoneNumber.error = this.phoneNumber.value ? this.$t('login.phoneNumberIsNotValid') : this.$t('login.phoneNumberIsNull')
        }
      }
    },
    otpCodeValidator() {
      // convert persian digit to english digit
      this.OPTCode.isValid = convertPersianNumberToEnglish(this.OPTCode.isValid)

      this.OPTCode.isValid = this.$validator('regex.OTPCode').test(this.OPTCode.value)
      if (!this.OPTCode.isValid) {
        this.OPTCode.error = this.OPTCode.value ? this.$t('login.otpCodeIsNotValid') : this.$t('login.otpCodeIsNull')
      }
    },

    sendOTPCode() {

      // check phone number is valid or not

      if (this.phoneNumber.isValid) {

        // send otp code to valid phone number
        sendOTPCode(this.phoneNumber.value).then(response => {

          // check user is super admin or not
          if (response.data.message === 'super_admin') {
            this.step = 'GET_SUER_ADMIN_TOKEN'
          } else {

            // show success message
            this.$vs.notify({
              time: 5000,
              title: this.$t('login.otp'),
              text: response.data.hasOwnProperty('message') ? this.$t('login.otpSendDynamic', {number: this.phoneNumber.value}) : response.data,
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'success'
            })

            this.step = 'OTP_CODE_SENT'
          }

        }).catch(error => {
          switch (error.response.status) {
            case 404:
              this.$vs.notify({
                time: 2500,
                title: this.$t('alert.error.title'),
                text: this.$t('login.notification.userNotFound'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
              break

            case 403:
              this.$vs.notify({
                time: 2500,
                title: this.$t('alert.error.title'),
                text: this.$t('login.notification.accessError'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
              break

            default:
              this.$vs.notify({
                time: 2500,
                title: this.$t('alert.error.title'),
                text: error.response.data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
              break
          }
          // show error message

        })
      }
    },
    resendOtpCode(voice = false) {

      // check phone number is valid or not

      if (this.phoneNumber.isValid) {
        resendOTPCode(this.phoneNumber.value, voice)
            .then(response => {

              // show success message
              if (!voice) this.$vs.notify({
                time: 5000,
                title: this.$t('login.otp'),
                text: response.data.hasOwnProperty('message') ? this.$t('login.otpSendDynamic', {number: this.phoneNumber.value}) : response.data,
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'success'
              })
              else this.$vs.notify({
                time: 2500,
                title: this.$t('alert.message.title'),
                text: this.$t('login.otpCall', {number: this.phoneNumber.value}),
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'success'
              })

              this.step = 'OTP_CODE_SENT'

              // reset timer
              this.resendOtp.reset = true
              this.resendOtp.status = false
              setTimeout(() => {
                this.resendOtp.reset = false
              }, 500)

            }).catch(error => {
          // show error message
          switch (error.response.status) {
            case 404:
              this.$vs.notify({
                time: 2500,
                title: this.$t('alert.error.title'),
                text: this.$t('login.notification.userNotFound'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
              break

            case 403:
              this.$vs.notify({
                time: 2500,
                title: this.$t('alert.error.title'),
                text: this.$t('login.notification.accessError'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
              break

            default:
              this.$vs.notify({
                time: 2500,
                title: this.$t('alert.error.title'),
                text: error.response.data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
              break
          }
        })
      }
    },

    handleOnChangeCode(value) {
      this.OPTCode.value = value
    },
    handleOnCompleteCode(value) {
      this.OPTCode.value = value
      this.changeStep('signup')
    },

    handleResetOtp() {
      this.resendOtp.reset = true
      this.resendOtp.status = false
      setTimeout(() => {
        this.resendOtp.reset = false
      }, 2000)
    },

    changeStep(step) {
      switch (step) {
        case 'OTP_CODE_SENT':
          if (this.phoneNumber.isValid && this.phoneNumber.value.length > 0) {
            // this.step = 'OTP_CODE_SENT'
            this.sendOTPCode()
            this.handleResetOtp()
          }
          setTimeout(() => {
            const otpInput = document.getElementById('otpCodeInput')
            if (otpInput) {
              otpInput.focus()
              otpInput.scrollIntoView()
            }
          }, 100)
          break

        case 'signup':
          this.login()
          // this.step = 'signup'
          break
      }
    },

    login() {
      // show success message
      this.$vs.notify({
        time: 5000,
        title: this.$t('login.notification.review.title'),
        text: this.$t('login.notification.review.message'),
        iconPack: 'feather',
        icon: 'icon-check',
        color: 'primary'
      })

      // check phone number and otp code are valid or not
      this.otpCodeValidator()

      if (this.phoneNumber.isValid && this.OPTCode.isValid) {
        const payload = {
          checkbox_remember_me: this.checkbox_remember_me,
          user: {
            phoneNumber: this.phoneNumber.value,
            otpCode: this.OPTCode.value
            // permissions: this.permissions
          },
          notify: this.$vs.notify,
          closeAnimation: this.$vs.loading.close
        }

        this.$store.dispatch('auth/loginUser', payload).then(() => {
          this.$nextTick(() => {
            this.$router.push({name: 'home'})
          })
        }).catch(() => {
          /*this.otpCode.value = ''
          this.resetOTP = true

          setTimeout(() => {
            this.resetOTP = false
          }, 500)*/
          this.$refs.otpInput.clearInput()
        })
      }
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.app-login {
  margin-top: 0 !important;
  height: 100%;
  display: flex;
  justify-content: center;
}

.login {
  $dark-bg: #282828;
  $dark-content: #ffffff;
  $danger: #b00000;
  $success: #00b000;

  display: flex;
  align-items: center;
  padding: 5px;
  background-color: $dark-bg;
  color: $dark-content;
  min-height: 100vh;
  /*background-color: #cdd3e1;*/

  input:focus {
    box-shadow: 0 0 0;
  }

  .card {
    flex-grow: 1;
    max-width: 400px;
    margin: auto;
    padding: 10px;
    text-align: center;
    border: none;

    .logo {
      .logo-img {
        height: 150px;
        width: 150px;
      }
    }

    .login-title {
      font-size: 17px;
      margin-bottom: 20px;
      color: $dark-content;
    }

    .login-content {
      margin-top: 15px;
      margin-bottom: 20px;
      font-size: 14px;
      color: #f8f8f8;

      b {
        color: $dark-content;
      }
    }

    .get-phone-number {
      text-align: right;
      margin-top: 50px;

      .vs-input {
        .vs-inputx.normal {
          border-color: $dark-content !important;
          direction: rtl;
          text-align: right;
        }

        span, i {
          color: $dark-content;
        }
      }

      .vs-input.input-icon-validate-danger {
        .vs-inputx {
          border-color: $danger !important;
          color: $danger;
        }

        span, i {
          color: $danger;
        }
      }

      .vs-input.input-icon-validate-success {
        .vs-inputx.normal {
          border-color: $success !important;
        }

        span, i {
          color: $success;
        }
      }

      .u-input {
        .u-input-box {
          background-color: transparent;
          color: #fff;

          .u-selected-item {
            color: #fff;
          }
        }
      }

      //input, .vs-inputx {
      //    text-align: left;
      //    direction: ltr;
      //    line-height: 35px;
      //    padding-top: 3px !important;
      //    padding-bottom: 3px !important;
      //    background-color: inherit;
      //    border-color: $dark-content;
      //    color: $dark-content !important;
      //}

      .input-label {
        left: unset;
        right: 10px;
      }

      .invalid-feedback {
        text-align: right;
      }
    }

    .otp-box {
      .otp-input {
        color: $dark-content;

        &:focus {
          border-bottom: 1px solid #ffffff;
        }
      }
    }

    label, .input-label {
      background: $dark-bg !important;
    }

    input {
      color: $dark-content;
    }

    .resend {
      margin-top: 20px;
      text-align: center;

      .resend-btn {
        background-color: $btn-primary;
        color: #ffffff;
        border: 1px solid $btn-primary;
        border-radius: 0.4rem;
        width: 100%;
        line-height: 35px;
      }
    }

    .signup {
      .signup-item {
        text-align: left;
        margin: 25px 0;

        .signup-item-label {
          .required {
            color: red;
          }

          b {
            color: $btn-primary;
          }
        }

        .gender-items {
          display: flex;
          justify-content: space-between;

          .gender-item {
            width: 49%;
            background-color: inherit;
            border: 1px solid $btn-primary-disable;
            border-radius: 0.2rem;
            color: $btn-primary-disable;
            line-height: 40px;
            transition: all ease 0.4s;

            &.active {
              border: 1px solid $btn-primary;
              background-color: $btn-primary;
              color: #ffffff;
            }
          }
        }

        .signup-item-input {
          line-height: 35px;
          background-color: $input-bg;

          &:focus {
            box-shadow: 0 0 0;
          }
        }

        &.form-check {
          display: flex;
          align-items: center;

          .form-check-input {
            margin-right: 10px;
            height: 25px;
            width: 25px;
          }
        }
      }
    }

    .btns {
      display: flex;
      margin-top: 20px;

      .send-btn {
        color: #ffffff;
        background-color: $btn-primary;
        border: 1px solid $btn-primary;
        border-radius: 0.4rem;
        flex-grow: 1;
        transition: all ease 0.4s;
        line-height: 35px;

        &:focus {
          box-shadow: 0 0 0;
        }

        &:disabled {
          border-color: $btn-primary-disable;
          background-color: $btn-primary-disable;
        }
      }
    }
  }

  .back-icon {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 10;
  }

  .help-btn {
    position: absolute;
    left: 15px;
    top: 15px;
    border: 0;
    padding: 0;
    color: #ffffff;
    background-color: inherit;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
}
</style>
